<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="offset-md-2 col-md-8">
         <card class="pb-4">
            <h4 slot="header" class="card-title">{{$t("change_password")}}</h4>
            <form>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                  <base-input type="password" v-model="password"  :label="$t('Password')" :placeholder="$t('Password')">
                      <template v-slot:textError v-if="$v.password.$error">
                        <span v-if="!$v.password.required">
                          {{ $t("password_required") }}
                        </span>
                        <span v-if="!$v.password.minLength">
                          {{ $t("password_length") }} 
                        </span>
                      </template>
                  </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                <base-input type="password" v-model="confirm_password"  :label="$t('confirmPassword')" :placeholder="$t('confirmPassword')">
                  <template  v-slot:textError v-if="$v.confirm_password.$error">
                    <span v-if="!$v.confirm_password.required">
                      {{ $t("confirm_password_required") }}
                    </span>
                    <span v-if="!$v.confirm_password.minLength">
                      {{ $t("confirm_password_length") }} 
                    </span>
                    <span v-if="!$v.confirm_password.sameAsPassword">
                      {{ $t("confirm_password_equal") }} 
                    </span>
                    
                  </template>
                </base-input>
                </div>
            </div>
            <div class="mt-3">
                <button type="button" class="btn btn-dark btn-fill float-right px-4" @click="onChangePassword">
                    {{$t("Actualizar")}}
                </button>
            </div>
            </form>
        </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required,minLength, sameAs } from 'vuelidate/lib/validators';

  export default {
    data(){
      return {
        password : null,
        confirm_password : null
      }
    },
    methods :{
      onChangePassword(){
        this.$v.$touch();
        let self = this;
        if(!this.$v.$invalid){
          this.$store.dispatch('isLoadingOn');
          this.$axios.post("/auth/change_password",{'password' : this.password})
            .then(()=>{
              self.password = null;
              self.confirm_password = null;
              self.$v.$reset();
              self.$store.dispatch('isLoadingOff');
                self.$dialog.alert({
                    title: self.$t("change_password"),
                    body: self.$t("change_password_success")
                });
                self.$v.$reset();
            })
            .catch(()=>{
              self.$store.dispatch('isLoadingOff');
               self.$dialog.alert({
                    title: self.$t("change_password"),
                    body: self.$t("server_error")
              });
            })
        }
      }
    },
    validations(){
      return {
        password  :{
          required,
          minLength : minLength(8)
        },
        confirm_password  :{
          required,
          minLength : minLength(8),
          sameAsPassword: sameAs('password')
        }
      }
    }
  }

</script>
<style>

</style>
